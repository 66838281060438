var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticClass: "card",
    attrs: {
      bordered: true
    }
  }, _vm._l(_vm.checkInfo, function (item, key) {
    return _c("div", {
      key: item,
      staticClass: "card_info"
    }, [_c("div", {
      staticClass: "card_info_label"
    }, [_vm._v(_vm._s(item))]), _c("div", {
      staticClass: "card_info_con"
    }, [_vm._v(_vm._s(_vm.checkDetail[key]))])]);
  }), 0), _vm.checkDetail.status == 0 ? _c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 审核通过 ")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 审核拒绝 ")])], 1)], 1) : _vm._e(), _vm.checkDetail.status == 0 ? _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["infoManagement:btn:issue"],
      expression: "['infoManagement:btn:issue']"
    }],
    staticClass: "btn_l",
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("提交 ")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };