import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticClass: "check_btn"
  }, _vm._l(_vm.checkType, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "check_btn_status",
      class: _vm.status == item.id ? "active" : "",
      on: {
        click: function click($event) {
          return _vm.handleClickTab(item.id);
        }
      }
    }, [_c("div", [_vm._v(" " + _vm._s(item.name) + " ")])]);
  }), 0), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading,
      scroll: {
        x: "1472px"
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record) {
          return [key == "action" ? _c("div", [_c("a", {
            on: {
              click: function click($event) {
                return _vm.addCollect(record.id, 1);
              }
            }
          }, [_vm._v("查看")]), _vm.status == 0 ? _c("span", [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.addCollect(record.id, 2);
              }
            }
          }, [_vm._v("审核")])], 1) : _vm._e()]) : _c("span", [_vm._v(_vm._s(record[key] || "---"))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("div", [_vm.checkVisible ? _c("a-modal", {
    attrs: {
      title: _vm.modalTit,
      visible: _vm.checkVisible,
      footer: false,
      maskClosable: false,
      width: "600px"
    },
    on: {
      cancel: _vm.cancel
    }
  }, [_c("CheckDetail", {
    attrs: {
      checkid: _vm.checkid
    },
    on: {
      cancel: _vm.cancel,
      handlesave: _vm.getList
    }
  })], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };